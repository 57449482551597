<template>
    <v-container fluid>
        <div class="main">
            <v-row v-if="loading">
                <v-col cols="12">
                    <v-card class="rounded-lg" flat>
                        <v-skeleton-loader type="table"></v-skeleton-loader>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-else>

                <v-col cols="12">
                    <div class="d-flex justify-space-between">
                        <v-btn color="gifty" depressed exact text to="/support">
                            <v-icon right class="mr-3">mdi-arrow-left</v-icon>
                            Retour
                        </v-btn>
                        <h3 class="mb-4">Invitations par envoi de solde</h3>
                    </div>
                    <v-card class="rounded-lg shadow">
                        <v-card-title>
                            <v-btn class="success--text text-none" depressed rounded>
                                Invitations:
                                <v-skeleton-loader v-if="loading" type="text" />
                                <span>{{ invitations.total }}</span>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>

                            <div v-if="invitations.data.length > 0">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Utilisateur</th>
                                                <th>Montant</th>
                                                <th>Date</th>
                                                <th>Telephone</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in invitations.data" :key="item.id">

                                                <td>
                                                    {{ item.id }}
                                                </td>

                                                <td style="cursor: pointer;" @click="showAccount(item?.account?.id)">
                                                    {{ item?.account?.user?.name }}
                                                </td>

                                                <td class="text-no-wrap font-weight-medium gifty--text">
                                                    {{ CurrencyFormatting(item.amount) }} DZD
                                                </td>

                                                <td class="text-no-wrap">
                                                    <v-icon small :style="{ marginTop: '-3px' }"
                                                        color="gifty">mdi-clock-outline
                                                    </v-icon>
                                                    {{ moment(item.created_at).format('DD/MM/YYYY hh:mm') }}
                                                </td>

                                                <td>
                                                    {{ item.phone }}
                                                </td>

                                                <td>
                                                    <v-tooltip top color="success">
                                                        <template v-slot:activator="{ on, attrs }">

                                                            <v-btn icon @click="resendInvitationSms(item)" v-bind="attrs"
                                                                v-on="on">
                                                                <v-icon class="success--text">mdi-message-text-fast</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Renvoyer le SMS</span>
                                                    </v-tooltip>

                                                    <v-tooltip top color="error">
                                                        <template v-slot:activator="{ on, attrs }">

                                                            <v-btn icon @click="handleDelete(item)" v-bind="attrs"
                                                                v-on="on">
                                                                <v-icon class="error--text">mdi-delete-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Supprimer l'invitation</span>
                                                    </v-tooltip>


                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                                <v-divider />

                                <div class="d-flex justify-space-between pl-4 pt-6 pr-4">

                                    <div :style="{ width: '80px' }">
                                        <v-select outlined v-model="filter.size" dense :items="[5, 10, 20, 30, 40, 50]"
                                            @change="fetchData"></v-select>
                                    </div>

                                    <v-spacer />

                                    <v-pagination total-visible="6" v-if="invitations.total > 15" circle
                                        v-model="filter.page" :length="Math.ceil(invitations.total / filter.size)"
                                        @input="fetchData"></v-pagination>

                                </div>

                            </div>

                            <div v-else class="text-center">
                                <v-avatar tile size="200">
                                    <v-img :src="require('@/assets/database.svg')"></v-img>


                                </v-avatar>
                                <p>Aucun enregistrement</p>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <ConfirmDelete ref="deleteInvitation" @refresh="fetchData" />
            <ResendInvitationSmsVue ref="resendInvitationSms" />
        </div>
    </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import ConfirmDelete from './ConfirmDelete.vue';

import ResendInvitationSmsVue from './ResendInvitationSms.vue';
export default {
    components: { ConfirmDelete, ResendInvitationSmsVue },
    data() {
        return {
            invitations: {},
            loading: true,
            filter: {
                page: 1,
                size: 10
            }
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            HTTP.get("v1/refill/invitations", {
                params: {
                    ...this.filter
                }
            }).then((res) => {
                this.invitations = res.data.data;
                this.loading = false;

            }).catch((e) => {
                this.loading = false;
                console.log(e);
            });
        },

        handleDelete(item) {
            this.$refs.deleteInvitation.open(item);
        },
        showAccount(account_id) {
            let route = this.$router.resolve({ path: "/users/" + account_id });
            window.open(route.href, "_blank");
        },

        resendInvitationSms(item) {
            this.$refs.resendInvitationSms.open(item);
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped></style>